var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-4" },
    [
      _c("vx-tour", {
        attrs: { name: "questionnaireAnswersIntroSteps", auto: true },
      }),
      _c(
        "div",
        [
          _c("div", { staticClass: "grid grid-cols-1 sm:grid-cols-6 w-full" }, [
            _c("div", { staticClass: "w-full flex justify-center" }, [
              _c(
                "div",
                {
                  staticClass: "flex bg-gray-200",
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.getAnswers(true)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-4 text-center py-2 ml-2 tour-answers-refresh",
                    },
                    [
                      _c("feather-icon", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.top",
                            value: {
                              content: "Atualizar",
                              delay: { show: 1000, hide: 100 },
                            },
                            expression:
                              "{\n                content: 'Atualizar',\n                delay: { show: 1000, hide: 100 }\n              }",
                            modifiers: { top: true },
                          },
                        ],
                        staticClass: "ml-1",
                        attrs: { icon: "RefreshCwIcon", svgClasses: "h-4 w-4" },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex-4 text-center py-1 px-4" }, [
                    _c("b", [_vm._v(_vm._s(_vm.$t("atualizar")))]),
                  ]),
                ]
              ),
            ]),
            _vm.hasAnswers
              ? _c(
                  "div",
                  { staticClass: "w-full flex justify-center text-sm" },
                  [
                    _c(
                      "vs-checkbox",
                      {
                        staticClass: "mb-3 tour-answers-1",
                        model: {
                          value: _vm.filterCorrected,
                          callback: function ($$v) {
                            _vm.filterCorrected = $$v
                          },
                          expression: "filterCorrected",
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("questao-sem-correcao")) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.hasAnswers
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-wrap w-full md:col-span-2 justify-center",
                  },
                  [
                    _c("div", { staticClass: "flex bg-gray-200" }, [
                      _c(
                        "div",
                        { staticClass: "flex-4 text-center py-1 px-2" },
                        [_c("b", [_vm._v(_vm._s(_vm.$t("agrupado-por")))])]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex-4 text-center py-1 px-2" },
                        [_vm._v(_vm._s(_vm.$t("usuario")))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-4 text-center py-1 ml-2 tour-answers-2",
                        },
                        [
                          _c("vs-switch", {
                            model: {
                              value: _vm.questionGrouped,
                              callback: function ($$v) {
                                _vm.questionGrouped = $$v
                              },
                              expression: "questionGrouped",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex-4 text-center py-1 px-4" },
                        [_vm._v(_vm._s(_vm.$t("questao")))]
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass:
                  "flex justify-end sm:justify-left py-1 px-4 w-full md:col-span-2 mt-4 md:mt-0",
              },
              [
                !_vm.isEmpty(_vm.usersList) && _vm.usersList.length > 0
                  ? _c("label", { staticClass: "text-center font-bold" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$tc(
                              "x_answered_questionnaire",
                              _vm.usersList.length,
                              { count: _vm.usersList.length }
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
          _vm.unfinishedCount > 0 && !_vm.questionnaireClosed
            ? _c(
                "div",
                { staticClass: "flex justify-items-center w-full" },
                [
                  _c(
                    "vs-alert",
                    {
                      staticClass: "mt-5 text-warning mb-4 h-auto",
                      attrs: {
                        title: _vm.$t("alerta"),
                        color: "rgb(231, 154, 23)",
                        active: "true",
                      },
                    },
                    [
                      _c("b", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.unfinishedCount) +
                            " participante" +
                            _vm._s(_vm.unfinishedCount > 1 ? "s" : "") +
                            " ainda " +
                            _vm._s(
                              _vm.unfinishedCount > 1
                                ? "não finalizaram"
                                : "não finalizou"
                            ) +
                            " a sua avaliação. Caso já tenha encerrado o período de aplicação, orientamos: "
                        ),
                      ]),
                      _c("span", { staticClass: "flex items-center mt-2" }, [
                        _c("div", {
                          staticClass:
                            "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-warning",
                        }),
                        _c("span", { staticClass: "text-sm text-warning" }, [
                          _vm._v(
                            " Situações que pode ocorrer para esse comportamento: participante não envio/finalizou a sua avaliação; fechou o aplicativo (navegador/browser); para avaliações com tempo, pode ser que o participante saiu da prova e o tempo foi encerrado. "
                          ),
                        ]),
                      ]),
                      _c("span", { staticClass: "flex items-center mt-4" }, [
                        _c("div", {
                          staticClass:
                            "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-warning",
                        }),
                        _c("span", { staticClass: "text-sm text-warning" }, [
                          _vm._v(
                            " Se você clicar em liberar notas no final da página, todos os questionários pendentes serão finalizados."
                          ),
                        ]),
                      ]),
                      _c("span", { staticClass: "flex items-center mt-4" }, [
                        _c("div", {
                          staticClass:
                            "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-warning",
                        }),
                        _c("span", { staticClass: "text-sm text-warning" }, [
                          _vm._v(
                            " Para o caso de questionários finalizados, basta liberar novamente que serão automaticamente encerradas as avaliações dos participantes."
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.questionGrouped
            ? _c(
                "vs-row",
                [
                  _c(
                    "vs-col",
                    [
                      _c("correction-question-page", {
                        attrs: {
                          model: _vm.currentQuestion,
                          fields: _vm.contentQuestions,
                          grid: _vm.grid,
                        },
                        on: {
                          showCommentModal: _vm.showCommentModal,
                          showCompleteQuestionModal:
                            _vm.showCompleteQuestionModal,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "grid grid-cols-4" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-span-4 lg:col-span-2",
                                        },
                                        [
                                          _vm.currentQuestion
                                            ? _c(
                                                "vs-select",
                                                {
                                                  attrs: {
                                                    "no-data-text": " ",
                                                    autocomplete: "",
                                                    label: _vm.$t("questao"),
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.pagination
                                                        .currentQuestion,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.pagination,
                                                        "currentQuestion",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "pagination.currentQuestion",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.questionsFiltered,
                                                  function (question, number) {
                                                    return _c(
                                                      "vs-select-item",
                                                      {
                                                        key: number,
                                                        attrs: {
                                                          value: number + 1,
                                                          text: `Questão ${
                                                            number + 1
                                                          } - ${_vm.shortTitle(
                                                            question.data.title
                                                          )}`,
                                                        },
                                                      }
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-span-4 md:mt-1 lg:col-span-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "lg:pt-6 lg:w-1/2 lg:float-right",
                                            },
                                            [
                                              _c("vs-pagination", {
                                                staticClass: "tour-answers-3",
                                                attrs: {
                                                  max: "8",
                                                  total:
                                                    _vm.questionsFiltered
                                                      .length,
                                                },
                                                model: {
                                                  value:
                                                    _vm.pagination
                                                      .currentQuestion,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.pagination,
                                                      "currentQuestion",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "pagination.currentQuestion",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2642693936
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.questionGrouped
            ? _c(
                "vs-row",
                [
                  _c(
                    "vs-col",
                    [
                      _c("correction-user-page", {
                        staticClass: "mb-4",
                        attrs: {
                          model: _vm.currentUser,
                          fields: _vm.contentQuestions,
                        },
                        on: {
                          secondChance: _vm.givenSecondChance,
                          showCompleteQuestionModal:
                            _vm.showCompleteQuestionModal,
                          showCommentModal: _vm.showCommentModal,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "grid grid-cols-6" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-span-6 mb-2 md:col-span-3 md:mb-0",
                                        },
                                        [
                                          _vm.currentUser
                                            ? _c(
                                                "vs-select",
                                                {
                                                  staticClass:
                                                    "select-auto-width",
                                                  attrs: {
                                                    "no-data": " ",
                                                    autocomplete: "",
                                                    label: _vm.$t("usuario"),
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.pagination
                                                        .currentUser,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.pagination,
                                                        "currentUser",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "pagination.currentUser",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.usersList,
                                                  function (user, number) {
                                                    return _c(
                                                      "vs-select-item",
                                                      {
                                                        key: number,
                                                        attrs: {
                                                          value: number + 1,
                                                          text: `${user.name} - ${user.email}`,
                                                        },
                                                      }
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-span-6 md:col-span-3",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md:pt-5 md:w-1/2 md:float-right",
                                            },
                                            [
                                              _c("vs-pagination", {
                                                attrs: {
                                                  max: "5",
                                                  total: _vm.usersList.length,
                                                },
                                                model: {
                                                  value:
                                                    _vm.pagination.currentUser,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.pagination,
                                                      "currentUser",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "pagination.currentUser",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1092944307
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.noAnswers
            ? _c(
                "vs-row",
                { staticClass: "flex text-center mt-4" },
                [
                  _c("vs-col", { staticClass: "w-full" }, [
                    _vm._v(
                      _vm._s(_vm.$t("nao-ha-respostas-disponiveis-clique"))
                    ),
                  ]),
                ],
                1
              )
            : _c(
                "vs-row",
                { staticClass: "flex text-center mt-4 mb-24" },
                [
                  _c(
                    "vs-col",
                    { staticClass: "flex w-1/2 float-left font-bold" },
                    [
                      _vm._v(
                        _vm._s(
                          !_vm.isEmpty(_vm.content) &&
                            !_vm.isEmpty(_vm.content.score_released_at)
                            ? `Notas liberadas em ${_vm.content.score_released_at_formatted}`
                            : ""
                        )
                      ),
                    ]
                  ),
                  _c(
                    "vs-col",
                    {
                      staticClass: "w-1/2 flex justify-end items-center gap-2",
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "vs-button",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.bottom-start",
                                  value: {
                                    content:
                                      "Processa todas as respostas enviadas e aplica a correção automática.",
                                    delay: { show: 1000, hide: 100 },
                                  },
                                  expression:
                                    "{\n              content: 'Processa todas as respostas enviadas e aplica a correção automática.',\n              delay: { show: 1000, hide: 100 }\n            }",
                                  modifiers: { "bottom-start": true },
                                },
                              ],
                              staticClass: "tour-answers-9",
                              attrs: { type: "border" },
                              on: { click: _vm.reapplyCorrection },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticStyle: { height: "14px", width: "14px" },
                                attrs: { icon: "cogs" },
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("atualizar-correcao"))
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "vs-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "questionnaires.release.notes",
                                  expression: "'questionnaires.release.notes'",
                                },
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.bottom-start",
                                  value: {
                                    content: "Liberar notas do questionário",
                                    delay: { show: 1000, hide: 100 },
                                  },
                                  expression:
                                    "{\n              content: 'Liberar notas do questionário',\n              delay: { show: 1000, hide: 100 }\n            }",
                                  modifiers: { "bottom-start": true },
                                },
                              ],
                              staticClass: "tour-answers-9",
                              attrs: {
                                icon: "playlist_play",
                                disabled: _vm.disableScoreReleaseButton,
                              },
                              on: { click: _vm.scoreReleaseConfirmation },
                            },
                            [_vm._v(_vm._s(_vm.$t("liberar-notas")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _c("vx-tour", {
        attrs: { name: "questionnaireAnswerHasAnswerSteps", auto: false },
      }),
      _c(
        "vs-popup",
        {
          attrs: {
            title: "",
            active: _vm.showCompleteQuestion,
            fullscreen: true,
          },
          on: {
            "update:active": function ($event) {
              _vm.showCompleteQuestion = $event
            },
          },
        },
        [
          _c("correction-complete-question", {
            attrs: {
              "complete-question": _vm.completeQuestion,
              "questionnaire-answer-service": _vm.questionnaireAnswerService,
            },
          }),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: { title: _vm.$t("comments"), active: _vm.showCommentSection },
          on: {
            "update:active": function ($event) {
              _vm.showCommentSection = $event
            },
          },
        },
        [_c("AnswerCommentSection", { ref: "commentsection" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }