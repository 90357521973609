var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.model
        ? _c("vx-card", { key: _vm.model, staticClass: "mb-4" }, [
            _c(
              "div",
              {
                staticClass: "w-full",
                attrs: { slot: "header" },
                slot: "header",
              },
              [_vm._t("header")],
              2
            ),
            _c(
              "p",
              { staticClass: "mb-3" },
              [
                _c(
                  "vs-table",
                  {
                    key: _vm.key,
                    attrs: { "no-data": " ", data: _vm.model.answers },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ data }) {
                            return _vm._l(data, function (tr, i) {
                              return _c(
                                "vs-tr",
                                {
                                  key: i,
                                  class: _vm.getRowClass(data[i], "question"),
                                  attrs: {
                                    state: _vm.correctionStatus(data[i]),
                                  },
                                },
                                [
                                  _c(
                                    "vs-td",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: data[i].user_email,
                                          expression: "data[i].user_email",
                                        },
                                      ],
                                      attrs: { data: data[i].user_name },
                                    },
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(data[i].user_name)),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[i].finished_at } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("moment")(
                                            data[i].finished_at,
                                            "DD/MM/YYYY kk:mm"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "answer",
                                      attrs: { data: data[i].answer },
                                    },
                                    [
                                      _c("question-answer", {
                                        attrs: {
                                          data: _vm.getCompleteQuestion(
                                            data[i]
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "w-score",
                                      attrs: { data: data[i].score },
                                    },
                                    [
                                      _c("vs-input", {
                                        attrs: {
                                          lang: "pt-BR",
                                          type: "number",
                                          disabled: _vm.invalidatedQuestion(
                                            data[i].question_id
                                          ),
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.scoreChanged(
                                              data[i],
                                              _vm.model.data.score
                                            )
                                          },
                                        },
                                        model: {
                                          value: data[i].score,
                                          callback: function ($$v) {
                                            _vm.$set(data[i], "score", $$v)
                                          },
                                          expression: "data[i].score",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("vs-td", { attrs: { data: data[i].id } }, [
                                    _c(
                                      "div",
                                      { staticClass: "flex flex-wrap" },
                                      [
                                        _c("vs-button", {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip.top",
                                              value: {
                                                content:
                                                  "Marcar questão como correta",
                                                delay: {
                                                  show: 1000,
                                                  hide: 100,
                                                },
                                              },
                                              expression:
                                                "{\n                    content: 'Marcar questão como correta',\n                    delay: { show: 1000, hide: 100 }\n                  }",
                                              modifiers: { top: true },
                                            },
                                          ],
                                          staticClass: "m-1 tour-answers-5",
                                          attrs: {
                                            type: "filled",
                                            icon: "check",
                                            disabled:
                                              _vm.disableCorrectedButton(
                                                data[i]
                                              ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.correctQuestion(
                                                true,
                                                data[i]
                                              )
                                            },
                                          },
                                        }),
                                        _c("vs-button", {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip.top",
                                              value: {
                                                content:
                                                  "Marcar questão como incorreta",
                                                delay: {
                                                  show: 1000,
                                                  hide: 100,
                                                },
                                              },
                                              expression:
                                                "{\n                    content: 'Marcar questão como incorreta',\n                    delay: { show: 1000, hide: 100 }\n                  }",
                                              modifiers: { top: true },
                                            },
                                          ],
                                          staticClass: "m-1 tour-answers-6",
                                          attrs: {
                                            type: "filled",
                                            color: "danger",
                                            icon: "clear",
                                            disabled: _vm.disableWrongButton(
                                              data[i]
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.correctQuestion(
                                                false,
                                                data[i]
                                              )
                                            },
                                          },
                                        }),
                                        _c("vs-button", {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip.top",
                                              value: {
                                                content:
                                                  "Visualizar questão completa",
                                                delay: {
                                                  show: 1000,
                                                  hide: 100,
                                                },
                                              },
                                              expression:
                                                "{\n                    content: 'Visualizar questão completa',\n                    delay: { show: 1000, hide: 100 }\n                  }",
                                              modifiers: { top: true },
                                            },
                                          ],
                                          staticClass: "m-1 tour-answers-7",
                                          attrs: {
                                            type: "filled",
                                            color: "warning",
                                            icon: "visibility",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showCompleteQuestionModal(
                                                data[i]
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "div",
                                          [
                                            _c("feather-icon", {
                                              staticClass:
                                                "float-right h-auto z-50 mr-3 mt-2 tour-answers-8",
                                              attrs: {
                                                badge: _vm.unreadComments(
                                                  data[i].comments,
                                                  data[i].question_id
                                                ),
                                                svgClasses: "text-success",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showCommentSectionModal(
                                                    _vm.field
                                                  )
                                                },
                                              },
                                            }),
                                            _c("vs-button", {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip.top",
                                                  value: {
                                                    content:
                                                      "Abrir comentários",
                                                    delay: {
                                                      show: 1000,
                                                      hide: 100,
                                                    },
                                                  },
                                                  expression:
                                                    "{\n                      content: 'Abrir comentários',\n                      delay: { show: 1000, hide: 100 }\n                    }",
                                                  modifiers: { top: true },
                                                },
                                              ],
                                              staticClass: "m-1",
                                              attrs: {
                                                type: "filled",
                                                color: "success",
                                                icon: "speaker_notes",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showCommentSectionModal(
                                                    data[i]
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              )
                            })
                          },
                        },
                      ],
                      null,
                      false,
                      2505964662
                    ),
                  },
                  [
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", { attrs: { width: "20%" } }, [
                          _vm._v(_vm._s(_vm.$t("participant"))),
                        ]),
                        _c("vs-th", { attrs: { width: "18%" } }, [
                          _vm._v(_vm._s(_vm.$t("data-entrega"))),
                        ]),
                        _c("vs-th", { attrs: { width: "43%" } }, [
                          _vm._v(_vm._s(_vm.$t("data_viewer.keys.answer"))),
                        ]),
                        _c("vs-th", { attrs: { width: "1%" } }, [
                          _vm._v(_vm._s(_vm.$t("points"))),
                        ]),
                        _c("vs-th", { attrs: { width: "18%" } }, [
                          _vm._v(_vm._s(_vm.$t("Actions"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._.isArray(_vm.model.answers) && _vm.model.answers.length == 0
              ? _c(
                  "div",
                  { staticClass: "w-full justify-center flex flex-wrap" },
                  [
                    _vm.hasRandomQuestions
                      ? _c(
                          "label",
                          {
                            staticClass:
                              "w-full text-center font-bold text-warning",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "este-questionario-possui-questoes-aleatorias"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("label", { staticClass: "w-full text-center" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("nao-ha-respostas-para-esta-questao")) +
                          " "
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "w-full pt-2 pb-8" },
              [
                _c("vs-pagination", {
                  staticClass: "float-right",
                  attrs: { max: "10", total: _vm.grid.total },
                  model: {
                    value: _vm.grid.page,
                    callback: function ($$v) {
                      _vm.$set(_vm.grid, "page", $$v)
                    },
                    expression: "grid.page",
                  },
                }),
              ],
              1
            ),
            _c("p"),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }